<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <!-- 顶部图片 -->
    <div class="header">CONTACT US</div>
    <div class="title">CONTACT US</div>
    <div class="address" v-html="Address"></div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import api from "@/utils/api";
export default {
  mounted() {
    //面包屑导航
    this.breadcrumbList = [
      { path: "/", title: "HOME" },
      { path: "/contactus", title: "CONTACT US" },
      { path: "", title: "CONTACT US" },
    ];
    this.companyAddress();
  },
  data() {
    return {
      breadcrumbList: [],
      Address: "", //公司地址
    };
  },
  components: {
    Breadcrumb,
  },
  methods: {
    //获取公司简介
    companyAddress() {
      api.companyAddress().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.Address = data.data.content;
        } else {
          this.$message.error(data.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-image: url("../../assets/index/carousel.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 186px;
  min-width: 1156px;
  font-size: 56px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  line-height: 186px;
  text-align: center;
}
.title {
  margin: 69px auto;
  font-size: 36px;
  font-family: Arial;
  font-weight: normal;
  color: #004a9c;
  text-align: center;
}
.address {
  width: 1156px;
  margin: 0 auto;

  margin-bottom: 100px;
}
</style>